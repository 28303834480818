import React from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import YoutubePlayer from './youtubePlayer';

import placeholder from '../images/news-placeholder.jpg';

export default class NewsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        axios
            .get(`${process.env.API_URL}/umbraco/api/news/get?take=5`)
            .then(result => {
                this.setState({
                    data: result.data,
                })
            })
            .catch(error => { })
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        }

        const { data } = this.state;
        let news;

        if (data.length > 0) {
            settings.infinite = data.length > 2;

            news = (
                <div className="container">
                    <div className="row text-center">
                        <Slider {...settings}>
                            {
                                data.map((news, idx) => {
                                    const photoUrl = `${process.env.API_URL}${news.Photo}`;
                                    const linkUrl = `/news-detail${news.Url.substring(0, news.Url.length - 1)}`;

                                    let columnItem = null;

                                    if (news.Photo) {
                                        columnItem = (
                                            <div className="news-card mb-xs">
                                                <img alt="Logo" src={photoUrl} />
                                            </div>
                                        );
                                    } else if (news.Video) {
                                        columnItem = (
                                            <div className="news-card mb-xs">
                                                <YoutubePlayer url={news.Video} />
                                            </div>
                                        );
                                    } else {
                                        columnItem = (
                                            <div className="news-card mb-xs">
                                                <img alt="Logo" src={placeholder} />
                                            </div>
                                        );
                                    }

                                    return (
                                        <div key={idx} className="item active">
                                            <div className="col-md-12">
                                                <div className="single_activites card">
                                                    {columnItem}
                                                    <h4 className="text text-contained">{news.Name.replace(/&nbsp;/g, '')}</h4>
                                                    <Link to={linkUrl}>
                                                        <span className="btn-contact-bg solid">Ver Mais</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            );
        } else {
            news = (
                <div className="section-padding section-title text-center">
                    <p>Sem notícias</p>
                </div>
            );
        }

        let header;

        if (this.props.showHeader) {
            header = (
                <div className="section-title text-center wow zoomIn">
                    <h2>Últimas Notícias</h2>
                    <span />
                </div>
            )
        }

        return (
            <section className="section-padding mb-15">
                <div className="container">
                    <div className="row">
                        {header}
                        {news}
                    </div>
                </div>
            </section>
        );
    }
}