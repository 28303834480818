import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'

import Slider from 'react-slick';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';

import Team from '../components/teamList'
import EventList from '../components/eventList'
import NewsList from '../components/newsList';
import YoutubePlayer from '../components/youtubePlayer';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/home/getfull`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
    };

    const { data } = this.state;
    let sliderContent = [];
    let services = [];
    let tagline = '';

    if (data) {
      sliderContent = [
        ...data.PromoImages.map(i => {
          return {
            content: i,
            type: 'image'
          }
        }),
        ...data.PromoVideos.map(i => {
          return {
            content: i,
            type: 'video'
          }
        })
      ];
      tagline = data.TagLine;
      services = data.Services;
    }

    return (
      <div>
        <section id="home" className="welcome-area">
          <div className="welcome-slider-area">
            <Slider {...settings}>
              {
                sliderContent.map((promo, idx) => {
                  return (
                    <div key={idx}>
                      {
                        promo.type === 'image'
                          ? <img src={`${process.env.API_URL}${promo.content}`} />
                          : <YoutubePlayer url={promo.content} />
                      }
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        </section>
        <section className="promotion section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="single_promo">
                  <h1>Em Laços</h1>
                  <h1 className="subTag">{tagline}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our_activites">
          <div className="container">
            <div className="row text-center">
              <div className="section-title wow zoomIn">
                <h2>Os Nossos Serviços</h2>
                <span></span>
              </div>
              {
                services.map((service, idx) => {
                  let serviceContent = '';
                  if (!service.Link) {
                    serviceContent = (
                      <div>
                        <img src={`${process.env.API_URL}${service.Photo}`} />
                        <h4>{service.Name}</h4>
                      </div>
                    );
                  } else {
                    serviceContent = (
                      <Link to={`/${service.Link}/`}>
                        <img src={`${process.env.API_URL}${service.Photo}`} />
                        <h4>{service.Name}</h4>
                      </Link>
                    );
                  }

                  return (
                    <div key={idx} className="col-md-4 col-sm-4 col-xs-12">
                      <div className="single_activites single_activites_color_one">
                        {serviceContent}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </section>
        <Team showHeader="true" />
        <EventList showHeader="true" />
        <NewsList showHeader="true" />
      </div>
    )
  }
}
