import React from 'react';

const YoutubePlayer = (props) => {
    let url;

    if (props.url) {
        url = (props.url.indexOf('watch?v=') > -1) ? props.url.replace("watch?v=", "embed/") : props.url;
    }

    return (
        <iframe src={url} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    );
};

export default YoutubePlayer;