import React from 'react'
import axios from 'axios'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
export default class Team extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    if (this.props.dataFromParam) {
      this.setState({
        data: this.props.data,
      })
    } else {
      axios
        .get(`${process.env.API_URL}/umbraco/api/team/getall`)
        .then(result => {
          this.setState({
            data: result.data,
          })
        })
        .catch(error => { })
    }
  }

  componentDidUpdate() {
    if (this.props.dataFromParam && this.props.data && this.state.data.length !== this.props.data.length ) {
      this.setState({
        data: this.props.data,
      })
    }
  }

  render() {
    let subHeader = ''

    if (this.props.showHeader) {
      subHeader = (
        <div className="section-title wow zoomIn">
          <h2>A Nossa Equipa</h2>
          <span />
        </div>
      )
    }

    let photoPlaceHolder = (
      <StaticQuery
        query={graphql`
          query {
            mainlogo: file(relativePath: { eq: "profile-placeholder.png" }) {
              childImageSharp {
                fluid(maxWidth: 262) {
                  ...GatsbyImageSharpFluid_noBase64
                  presentationWidth
                }
              }
            }
          }
        `}
        render={data => (
          <Img
            fluid={data.mainlogo.childImageSharp.fluid}
            title="Em Laços"
            alt="Em Laços"
            className="img-responsive"
          />
        )}
      />
    )

    return (
      <section id="team" className="template_team">
        <div className="container">
          <div className="row text-center">
            {subHeader}
            {this.state.data.map((member, idx) => {
              let photo

              if (member.Photo) {
                photo = (
                  <img
                    className="img-responsive"
                    src={`${process.env.API_URL}${member.Photo}`}
                    alt=""
                  />
                )
              } else {
                photo = photoPlaceHolder
              }

              return (
                <div key={idx} className="col-md-3 col-sm-6">
                  <div className="single_team">
                    <Link to={`/team-member/${member.Id}`}>
                      {photo}
                      <div className="team-name">
                        <div>{member.Name}</div>
                        <div className="team-title">{member.Title}</div>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}
